<script>
  import { link } from "svelte-routing";
  import { imageSizeFolder } from "../helpers/imagesHelper";

  export let house;
  export let onUrlChange;

  const imageUrl = `${house.imageUrl}/${imageSizeFolder()}/${
    house.topImageName
  }`;
</script>

<a
  class="card"
  href={`himlalyckan-${house.id}`}
  use:link
  on:click={onUrlChange}
>
  <div class="card__image-container">
    <img class="card__image" src={imageUrl} alt="House" />
  </div>

  <div class="card__info">
    <h2 class="card__heading">{house.name}</h2>

    <p class="card__paragraph">
      <span class="demi-bold">Antal bäddar: </span>
      {house.numberOfBeds}
    </p>

    <p class="card__paragraph">
      <span class="demi-bold">Pris per vecka: </span>
      {house.price}
    </p>

    <div class="card__read-more-section">
      <div class="card__read-more">
        <span class="card__read-more-text">Läs mer</span>
        <span class="card__read-more-icon-container">
          <svg
            class="card__read-more-icon"
            aria-hidden="true"
            focusable="false"
            role="img"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            /></svg
          >
        </span>
      </div>
    </div>
  </div>
</a>

<style type="text/scss">.card-container__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.card-container {
  background-color: var(--background-color-beige);
}

.card-container__content {
  padding: var(--padding-xl) 0;
}

.card {
  background-color: var(--color-white);
  display: block;
  width: 100%;
  box-shadow: 0 0 4px #dbdbdb;
  border-radius: 2px;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
}
.card:not(:first-child) {
  margin-top: 50px;
}
.card:active {
  box-shadow: 0 0 4px #9e9e9e;
}

.card__image-container {
  overflow: hidden;
  width: 100%;
  max-height: 250px;
}

.card__image {
  width: 100%;
}

.card__info {
  padding: var(--padding-m);
}

.card__heading {
  margin-bottom: var(--margin-s);
  text-transform: uppercase;
  font-weight: 600;
}

.card__paragraph {
  margin: var(--margin-xs) 0;
}

.card__read-more {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: var(--margin-l);
}

.card__read-more-text {
  font-weight: 500;
}

.card__read-more-icon-container {
  padding-left: 7px;
  display: flex;
  align-items: center;
}

.card__read-more-icon {
  height: 15px;
}

@media (min-width: 481px) {
  .card-container__content {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    width: calc(50% - 15px);
    margin-right: 30px;
  }
  .card:nth-child(2n) {
    margin-right: 0;
  }
  .card:not(:first-child) {
    margin-top: 0;
  }
  .card:nth-child(3), .card:nth-child(4) {
    margin-top: 30px;
  }

  .card__image-container {
    overflow: hidden;
    width: 100%;
    max-height: none;
    height: 220px;
  }
}
@media (min-width: 1025px) {
  .card-container__content {
    padding: 80px 0;
  }

  .card {
    display: block;
    width: calc(50% - 25px);
    /* Minus half the margin between the cards */
    margin-right: 50px;
  }
  .card:nth-child(2n) {
    margin-right: 0;
  }
  .card:nth-child(3), .card:nth-child(4) {
    margin-top: 50px;
  }
  .card:hover {
    box-shadow: 0 0 4px #9e9e9e;
  }
  .card:hover .card__read-more-text,
.card:hover .card__read-more-icon-container {
    position: relative;
  }
  .card:hover .card__read-more-text::after,
.card:hover .card__read-more-icon-container::after {
    content: "";
    position: absolute;
    height: 0.5px;
    background-color: var(--text-color);
    width: 100%;
    top: calc(100% + 2px);
    left: 0;
  }

  .card__heading {
    font-size: 2rem;
  }

  .card__image-container {
    height: 330px;
  }

  .card__image {
    min-height: 100%;
  }
}</style>
