<script>
  import { imageSizeFolder } from "../../../helpers/imagesHelper";
  export let house;

  const imageUrl = `${house.imageUrl}/${imageSizeFolder()}/${
    house.topImageName
  }`;
</script>

<section class="top-image-wrapper">
  <div class="top-image-wrapper__inner">
    <img src={imageUrl} class="top-image" alt="bild på hus" />
    <h1 class="house-title">{house.name}</h1>
  </div>
</section>

<style type="text/scss">.top-image-wrapper {
  width: 100%;
  height: 230px;
  overflow: hidden;
  position: relative;
  background-color: var(--background-color-light-beige);
}

.top-image {
  width: 100%;
}

.house-title {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-white);
  letter-spacing: 0.2rem;
  width: calc(100% - 30px);
  text-align: center;
  padding: 15px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53);
}

@media (min-width: 481px) {
  .top-image-wrapper {
    height: 400px;
  }
}
@media (min-width: 1025px) {
  .top-image-wrapper {
    height: 700px;
  }

  .top-image-wrapper__inner {
    width: var(--wrapper-width);
    margin: var(--margin-l) auto 0;
  }
}</style>
