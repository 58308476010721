<footer class="footer">
  <div class="footer__content">
    <h3>Kontakt</h3>
    <p>himlalyckan@outlook.com</p>
  </div>
</footer>

<style type="text/scss">.footer__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.footer {
  background-color: var(--color-white);
  color: var(--text-color);
  border-top: 1px solid var(--border-color-beige-dark);
}

.footer__content {
  padding: var(--padding-l) 0;
  text-align: center;
}</style>
