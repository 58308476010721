<script>
  export let house;
</script>

<section class="description-section">
  <div class="description-section__content">
    <p class="description-section__text">{house.description}</p>

    <article class="info-list">
      <div class="info-list-item">
        <span class="info-list-item__type">Antal bäddar: </span>
        <span class="info-list-item__value">{house.numberOfBeds}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Pris per vecka: </span>
        <span class="info-list-item__value">{house.price}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Storlek: </span>
        <span class="info-list-item__value">{house.size}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Faciliteter: </span>
        <span class="info-list-item__value">{house.facilities}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Köksutrustning: </span>
        <span class="info-list-item__value">{house.kitchenFacilities}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Övrigt: </span>
        <span class="info-list-item__value">{house.otherInfo}</span>
      </div>
    </article>
  </div>
</section>

<style type="text/scss">.description-section__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

:root {
  /* Colors */
  --background-color-beige: #f5f1ec;
  --background-color-light-beige: #fafaf7;
  --text-color: #121212;
  --text-color-light: #262626;
  --color-white: #fff;
  --color-black: #000;
  --border-color-beige: #eee8e1;
  --border-color-beige-dark: #afaaa4;
  /* Widths */
  --wrapper-width: 90vw;
}

/* Desktop */
@media (min-width: 1025px) {
  :root {
    --wrapper-width: 950px;
  }
}
/* Larger desktop */
@media (min-width: 1200px) {
  :root {
    --wrapper-width: 1100px;
  }
}
.description-section {
  background-color: var(--background-color-light-beige);
  padding: var(--padding-m) 0;
}

.description-section__text {
  max-width: 90ch;
}

.info-list {
  margin: var(--margin-m) 0 var(--margin-m) 0;
}

.info-list-item {
  margin-top: 15px;
  font-size: 1.6rem;
  border-radius: 5px;
  padding: 15px 20px;
  background-color: #d3c1b8;
}

.info-list-item__type {
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 481px) {
  .info-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
  }

  .info-list-item:not(:last-child) {
    margin-right: 10px;
  }
}</style>
