<div class="contact-page-content-container">
  <section class="contact">
    <div class="contact__content">
      <h1>Kontakt</h1>
      <p>Tina Lundkvist</p>
      <p><span class="contact__title">Telefon:</span> +4676-772 21 00</p>
      <p><span class="contact__title">Epost:</span> himlalyckan@outlook.com</p>
      <p>
        <span class="contact__title">Adress:</span> Grankullavägen 281, 38775 Byxelkrok
      </p>
    </div>
  </section>

  <section class="section-directions">
    <div class="section-directions__content">
      <h2>Vägbeskrivning</h2>
      <p>
        Kör mot Byxelkrok på norra Öland. Passera rondell med avtagsväg mot Böda
        Sands camping, fortsätt ett par km, sväng höger vid skylt "Grankullavik
        7". Kör dessa 7 km, direkt efter ortsskylt Grankullavik är det en
        vänstersväng, när den övergår till högersväng ligger stugan på vänster
        sida ca 50 m innan avtagsvägen till Trollskogen vid havet.
      </p>
    </div>
  </section>
</div>

<style type="text/scss">.section-directions__content, .contact__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.contact-page-content-container {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.contact {
  background-color: var(--background-color-light-beige);
}

.contact__content {
  padding: var(--padding-l) 0;
}

.contact__title {
  font-weight: 600;
}

.section-directions {
  background-color: var(--background-color-beige);
}

.section-directions__content {
  padding: var(--padding-l) 0;
}</style>
