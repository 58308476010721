<section class="tips">
  <section class="tips-block tips-block--white">
    <div class="tips-block__content">
      <h1 class="tips-block__heading">Att göra i närområdet</h1>
      <div class="tips-block__body">
        <div class="tips-block__left-col">
          <p>
            Himlalyckans stugor är belägna ca 100 m från havet vid
            Grankullaviken med flera badplatser och en mysig liten fiskehamn där
            man också kan bada från bryggan. Från stranden ser man fyren Långe
            Erik avteckna sig mot horisonten.
          </p>

          <p>
            Stugorna ligger vid avfarten till Trollskogens naturreservat, och
            här är mycket naturskönt och speciellt. Det är endast två km till
            östra sidan och de milslånga sandstränderna vid Bödabukten. Vid
            Bödabukten finns badplatser som Fagerrör, Homrevet, Lyckesand samt
            Böda Sand, där Böda Sands camping också har en hel del aktiviteter
            för hela familjen.
          </p>

          <p>
            Nära till Byxelkrok med sin genuina fiskehamn, mysiga cafeer och
            restauranger samt småbutiker vid havet. Här går också färja till och
            från Oskarshamn samt båt till det mytomspunna naturreservatet på ön
            Blå Jungfrun.
          </p>

          <p>
            För mer information om aktiviteter, naturupplevelser och besöksmål
            läs gärna mer på <a href="www.norraoland.se">www.norraoland.se</a>.
          </p>
        </div>
        <div class="tips-block__right-col">
          <img
            class="tips-block__image"
            src="/assets/images/stranden.jpg"
            alt="Stranden"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="tips-block">
    <div class="tips-block__content">
      <h2 class="tips-block__heading">
        Ett axplock av besöksmål att rekommendera
      </h2>

      <ul class="tips__list tips__list--columns">
        <li class="tips__list-item">
          Trollskogens naturreservat med säregen, fantastisk natur och mycket
          aktiviteter för hela familjen.
        </li>
        <li class="tips__list-item">
          Neptuni Åkrar, ett unikt klapperstensfält längs kusten där enbart
          blåeld trivs och som fick sitt namn av Carl von Linne 1741 efter
          havsguden Neptunus.
        </li>
        <li class="tips__list-item">
          Lindreservatet med en mångfald av växter och djur samt ett komplex av
          husgrunder, gravfält och fägator sedan bronsåldern och järnåldern.
        </li>
        <li class="tips__list-item">
          Blå Jungfrun, också kallad Blåkulla, dit påskkärringarna enligt sägnen
          far på påsken. Nu en nationalpark med mycket speciell natur, båt från
          Byxelkrok.
        </li>
        <li class="tips__list-item">
          Stenkustvägen som går längs kusten från Byxelkrok till Äleklinta, med
          oväntade upptäckter och fantastisk natur.
        </li>

        <li class="tips__list-item">
          Byrums raukar Horns Kungsgård och Lilla Horns lövängar.
        </li>

        <li class="tips__list-item">
          Kust till kust vandringsled, en sex mil lång led mellan östra och
          västra sidan som passerar en mängd sevärd natur.
        </li>

        <li class="tips__list-item">
          Källa Ödekyrka bör man inte missa, och i dess närhet finns det mycket
          speciella Högenäs orde med fyrbåk och vårdkasar samt mängder av
          orkideer
        </li>

        <li class="tips__list-item">
          Södra Greda naturreservat där mängder av orkideer samsas på underbara
          strandängar. Östra sidan är genuint öländskt med natursköna hamnar,
          historiska kapell och artrika lövängar.
        </li>
      </ul>
    </div>
  </section>

  <div class="tips-block tips-block--white">
    <div class="tips-block__content">
      <div class="tips-row">
        <article class="tips__box">
          <h2>Aktiviteter</h2>
          <ul class="tips__list">
            <li class="tips__list-item">Böda Skogsjärnväg</li>
            <li class="tips__list-item">Dykbåten Oktan i Byxelkroks hamn</li>
            <li class="tips__list-item">
              Golf i Enerum strax utanför Byxelkrok
            </li>
            <li class="tips__list-item">
              Gokart i Glabo, finns även på andra ställen på Öland.
            </li>
            <li class="tips__list-item">Lådbilslandet i Löttorp</li>
            <li class="tips__list-item">Ormöga Kamelranch</li>
          </ul>
        </article>

        <article class="tips__box">
          <h2>Missa inte att besöka</h2>
          <ul class="tips__list">
            <li class="tips__list-item">Fyren Långe Erik</li>
            <li class="tips__list-item">Skäftekärr museum och järnåldersby</li>
            <li class="tips__list-item">Solliden Borgholms slottsruin</li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>

<style type="text/scss">.tips-block__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.tips {
  background-color: var(--background-color-light-beige);
}

.tips-block {
  padding: 30px 0;
}

.tips-block--white {
  background-color: var(--color-white);
}

.tips-block__body {
  display: flex;
  flex-direction: column;
}
.tips-block__body p {
  font-size: 1.6rem;
}

.tips__list {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-block-start: 25px;
  padding-left: 18px;
}

.tips__list-item {
  line-height: 2.6rem;
  margin-bottom: 20px;
}

.tips__list-item:not(:first-child) {
  margin-top: var(--margin-s);
}

.tips__box {
  box-sizing: border-box;
  padding: var(--padding-l) var(--padding-m);
  background-color: var(--background-color-beige);
  text-align: center;
  border-radius: 30px;
}
.tips__box:not(:first-child) {
  margin-top: 20px;
}

.tips__box .tips__list {
  list-style: none;
  padding-left: 0;
  font-size: 1.8rem;
}

@media (max-width: 480px) {
  .tips-block__image {
    width: 100%;
  }

  .tips-block__right-col {
    margin-top: 20px;
  }
}
@media (min-width: 481px) {
  .tips-block {
    padding: 50px 0;
  }

  .tips-block__body {
    flex-direction: row;
  }

  .tips-block__image {
    max-height: 400px;
  }

  .tips__list--columns {
    display: block;
    column-count: 2;
    column-gap: 50px;
  }

  .tips-block__left-col {
    margin-right: 45px;
  }

  .tips-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .tips__box {
    width: calc(50% - 10px);
    padding: var(--padding-l) var(--padding-m);
  }
  .tips__box:not(:first-child) {
    margin-top: 0;
  }
}</style>
