<section class="intro-text">
  <div class="intro-text__content">
    <h1 class="intro-text__heading">Välkommen till Himlalyckan</h1>

    <p class="intro-text__paragraph">
      Stugor att hyra i underbara Grankullavik vid havet och Ölands norra spets.
    </p>
  </div>
</section>

<style type="text/scss">.intro-text__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.intro-text {
  background-color: var(--background-color-beige-light);
}

.intro-text__content {
  padding: var(--padding-xl) var(--padding-s);
  text-align: center;
}

.intro-text__heading {
  font-size: 4rem;
  font-weight: 500;
  font-family: TelmaLight;
}

.intro-text__paragraph {
  color: var(--text-color-light);
}

.intro-text__paragraph:last-child {
  margin-bottom: 0;
}

@media (min-width: 1025px) {
  .intro-text__content {
    padding: 80px 0;
  }

  .intro-text__heading {
    font-size: 7rem;
    font-weight: 500;
  }

  .intro-text__paragraph {
    font-size: 2rem;
  }
}</style>
