<script>
  import { Router, Route } from "svelte-routing";
  import houses from "./texts/houses.js";
  import { onNavigationWithHash } from "./helpers/navigationHelper";

  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import StartPage from "./pages/StartPage/StartPage.svelte";
  import HousePage from "./pages/HousePage/HousePage.svelte";
  import ContactPage from "./pages/ContactPage/ContactPage.svelte";
  import TipsPage from "./pages/TipsPage/TipsPage.svelte";

  export let url = "";
  let pathname = window.location.pathname;

  $: if (window.location.hash) {
    onNavigationWithHash(window.location.hash);
  }

  const onUrlChange = () => {
    setTimeout(() => {
      pathname = window.location.pathname;
    }, 100);
  };
</script>

<Router {url}>
  <Header {pathname} {onUrlChange} />
  <main>
    <Route path="himlalyckan-1">
      <HousePage id={1} {houses} />
    </Route>

    <Route path="himlalyckan-2">
      <HousePage id={2} {houses} />
    </Route>

    <Route path="himlalyckan-3">
      <HousePage id={3} {houses} />
    </Route>

    <Route path="himlalyckan-4">
      <HousePage id={4} {houses} />
    </Route>

    <Route path="naromradet">
      <TipsPage />
    </Route>

    <Route path="kontakt">
      <ContactPage />
    </Route>

    <Route path="/">
      <StartPage {onUrlChange} />
    </Route>
  </main>

  {#if pathname !== "/kontakt"}
    <Footer />
  {/if}
</Router>

<style type="text/scss">/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  /* Colors */
  --background-color-beige: #f5f1ec;
  --background-color-light-beige: #fafaf7;
  --text-color: #121212;
  --text-color-light: #262626;
  --color-white: #fff;
  --color-black: #000;
  --border-color-beige: #eee8e1;
  --border-color-beige-dark: #afaaa4;
  /* Widths */
  --wrapper-width: 90vw;
}

/* Desktop */
@media (min-width: 1025px) {
  :root {
    --wrapper-width: 950px;
  }
}
/* Larger desktop */
@media (min-width: 1200px) {
  :root {
    --wrapper-width: 1100px;
  }
}
:root {
  --padding-xs: 5px;
  --padding-s: 10px;
  --padding-m: 20px;
  --padding-l: 30px;
  --padding-xl: 50px;
  --margin-xs: 5px;
  --margin-s: 10px;
  --margin-m: 20px;
  --margin-l: 30px;
}

:global(.flex-start-stretch) {
  display: flex;
  align-items: stretch;
}

:global(.demi-bold) {
  font-weight: 500;
}

@font-face {
  font-family: "TelmaLight";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  /* Read next point */
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
  src: url("/assets/fonts/Telma-Light.woff") format("woff");
}
@font-face {
  font-family: "TelmaRegular";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  /* Read next point */
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
  src: url("/assets/fonts/Telma-Regular.woff") format("woff");
}
:global(html, body) {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next", "Helvetica", sans-serif;
  font-size: 10px;
  letter-spacing: 0.5px;
  height: 100%;
}

:global(body) {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

:global(h1, h2, h3, h4, h5, h6) {
  margin-top: 0;
  margin-bottom: var(--margin-xs);
}

:global(h1) {
  font-size: 2.2rem;
  margin-bottom: var(--margin-s);
}

:global(h2) {
  font-size: 2rem;
}

:global(h3) {
  font-size: 1.8rem;
}

:global(p) {
  font-size: 1.6rem;
  line-height: 2.6rem;
}

:global(a) {
  font-size: 1.6rem;
}

@media (min-width: 1025px) {
  :global(h1) {
    font-size: 3.5rem;
    margin-bottom: var(--margin-s);
  }

  :global(h2) {
    font-size: 3rem;
  }

  :global(h3) {
    font-size: 2.5rem;
  }

  :global(p) {
    font-size: 1.5rem;
  }

  :global(a) {
    font-size: 1.5rem;
  }
}</style>
