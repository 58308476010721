<script>
  import { onMount } from "svelte";
  import { imageSizeFolder } from "../../../helpers/imagesHelper";

  export let house;

  const imagesUrl = `/assets/images/${house.id}/${imageSizeFolder()}`;
  let images = [];
  let selectedImageIndex = null;

  onMount(() => {
    const newImages = [];

    for (let i = 1; i <= house.numberOfImages; i++) {
      newImages.push(`${imagesUrl}/${i}.jpg`);
    }

    images = newImages;
  });

  const handleImageClick = (clickedImageIndex) => {
    selectedImageIndex = clickedImageIndex;
  };

  const handleCloseModal = (e) => {
    if (
      !e.target.classList.contains("images-modal__button") &&
      !e.target.classList.contains("images-modal__image") &&
      !e.target.classList.contains("images-modal__button-icon") &&
      !e.target.classList.contains("images-modal__button-icon-path")
    ) {
      selectedImageIndex = null;
    }
  };

  const handleNextClick = () => {
    if (selectedImageIndex === images.length - 1) {
      selectedImageIndex = 0;
      return;
    }

    selectedImageIndex++;
  };

  const handlePreviousClick = () => {
    if (selectedImageIndex === 0) {
      selectedImageIndex = images.length - 1;
      return;
    }

    selectedImageIndex--;
  };

  document.addEventListener("keydown", (e) => {
    if (selectedImageIndex !== null) {
      if (e.key === "ArrowLeft") {
        handlePreviousClick();
      } else if (e.key === "ArrowRight") {
        handleNextClick();
      }
    }
  });
</script>

<section class="images-section">
  <div class="images-section__content">
    {#each images as imageUrl, i}
      <img
        class="images-section__image"
        src={imageUrl}
        alt="house"
        on:click={() => handleImageClick(i)}
      />
    {/each}
  </div>

  {#if selectedImageIndex !== null}
    <div class="images-modal-wrapper">
      <div class="images-modal-overlay" on:click={handleCloseModal} />
      <article class="images-modal" on:click={handleCloseModal}>
        <button
          class="images-modal__button images-modal__button--prev"
          on:click={handlePreviousClick}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="images-modal__button-icon"
            ><path
              class="images-modal__button-icon-path"
              fill="currentColor"
              d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
            /></svg
          >
        </button>

        <img class="images-modal__image" src={images[selectedImageIndex]} />

        <button
          class="images-modal__button images-modal__button--next"
          on:click={handleNextClick}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            viewBox="0 0 448 512"
            class="images-modal__button-icon"
            ><path
              class="images-modal__button-icon-path"
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            />
          </svg>
        </button>
      </article>
    </div>
  {/if}
</section>

<style type="text/scss">@charset "UTF-8";
.images-section__content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.images-section {
  padding: var(--padding-m) 0;
  min-height: 400px;
  background-color: var(--background-color-beige);
}

.images-section__content {
  padding: var(--padding-xl) 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 160px;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
}

.images-section__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.images-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images-modal-overlay {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.images-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  height: 80vh;
}

.images-modal__image {
  height: auto;
  width: 100%;
}

.images-modal__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-color);
  font-size: 30px;
  background-color: var(--background-color-beige);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.images-modal__button--prev {
  left: 10px;
}
.images-modal__button--next {
  right: 10px;
}
.images-modal__button:hover {
  box-shadow: 0px 0px 7px 2px rgba(18, 18, 18, 0.49);
  cursor: pointer;
}
.images-modal__button:active, .images-modal__button:focus {
  outline: 0;
}

.images-modal__button-icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 481px) {
  .images-section__content {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 200px;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }

  .images-modal {
    justify-content: center;
  }

  .images-modal__button {
    width: 50px;
    height: 50px;
  }
  .images-modal__button--prev {
    left: 20px;
  }
  .images-modal__button--next {
    right: 20px;
  }

  .images-modal__image {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1025px) {
  .images-section__content {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 300px;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }

  .images-section__image {
    box-shadow: 0px ​0px 5px rgba(0, 0, 0, 0.3);
  }

  .images-section__image:hover {
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .images-modal__button {
    font-size: 50px;
    width: 70px;
    height: 70px;
  }
  .images-modal__button--prev {
    left: 50px;
  }
  .images-modal__button--next {
    right: 50px;
  }
}</style>
