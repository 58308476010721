<script>
  import { link } from "svelte-routing";

  export let onUrlChange;
  export let pathname;

  let menuOpen = false;

  const handleMenuClick = (e) => {
    e.stopPropagation();
    menuOpen = !menuOpen;
  };

  const onMenuLinkClick = () => {
    menuOpen = false;
    onUrlChange();
  };
</script>

<header>
  <div class="header-content">
    {#if pathname !== "/"}
      <a href="/" class="header-center" use:link on:click={onUrlChange}>
        <h1 class="site-title">Himlalyckan</h1>
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="site-title-icon"
        >
          <path
            fill="currentColor"
            d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"
            class=""
          />
        </svg>
      </a>
    {/if}

    <div class="header-right">
      <button class="menu-button" on:click={handleMenuClick}>
        <svg class="menu-button__icon" viewBox="0 0 100 80" width="30">
          <rect width="100" height="10" />
          <rect y="30" width="100" height="10" />
          <rect y="60" width="100" height="10" />
        </svg>
      </button>

      <div
        class="menu-overlay"
        class:open={menuOpen}
        on:click={handleMenuClick}
      />
      <section class="menu-wrapper" class:open={menuOpen}>
        <button class="close-menu-btn" on:click={handleMenuClick}>
          <svg
            class="close-menu-btn__icon"
            width="18px"
            height="16px"
            viewBox="0 0 18 16"
          >
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g transform="translate(-69.000000, -24.000000)" stroke="#121212">
                <g transform="translate(48.000000, 0.000000)">
                  <g transform="translate(22.000000, 24.000000)">
                    <path
                      d="M0.5,0 L16,15.75"
                      transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "
                    />
                    <path d="M0.5,0 L16,15.75" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>

        <nav class="navigation">
          <a
            on:click={onMenuLinkClick}
            class={`navigation__link ${
              pathname === "/" ? "navigation__link--active" : ""
            }`}
            href="/"
            use:link
          >
            Start
          </a>
          <a
            on:click={onMenuLinkClick}
            class={`navigation__link ${
              pathname === "/naromradet" ? "navigation__link--active" : ""
            }`}
            href="/naromradet"
            use:link
            >Närområdet
          </a>
          <a
            on:click={onMenuLinkClick}
            class={`navigation__link ${
              pathname === "/kontakt" ? "navigation__link--active" : ""
            }`}
            href="/kontakt"
            use:link
          >
            Kontakt
          </a>
        </nav>
      </section>
    </div>
  </div>
</header>

<style type="text/scss">@charset "UTF-8";
.header-content {
  max-width: var(--wrapper-width);
  margin: 0 auto;
  box-sizing: border-box;
}

header {
  background-color: var(--background-color-beige);
  padding: var(--padding-m) 0;
  border-bottom: 1px solid var(--border-color-beige-dark);
}

.header-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.site-title {
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.3rem;
  color: var(--text-color);
}

.site-title-icon {
  display: none;
}

.menu-button {
  background-color: transparent;
  border: none;
  /* Lägger till padding och margin för större click area */
  padding-right: var(--padding-m);
  margin-right: calc(var(--padding-m) * -1);
  display: flex;
}
.menu-button:focus {
  outline: 0;
}
.menu-button:active {
  outline: 0;
}

.menu-button__icon {
  fill: var(--text-color);
  width: 25px;
}

.menu-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  opacity: 0;
}
.menu-overlay.open {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.menu-wrapper {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(100% - 60px);
  padding: var(--padding-l) 0;
  background-color: white;
  box-shadow: 0px 2px 3px 0px #4e4e4e;
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 200ms ease;
  -moz-transition: -moz-transform 200ms ease;
  -o-transition: -o-transform 200ms ease;
  -ms-transition: -ms-transform 200ms ease;
  transition: transform 200ms ease;
}
.menu-wrapper.open {
  transform: translateX(0);
}

.close-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--padding-m);
  background-color: transparent;
  border: none;
}
.close-menu-btn:focus {
  outline: 0;
}

.close-menu-btn__icon {
  width: 22px;
  height: 25px;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--margin-l);
  letter-spacing: 0.7px;
}

.navigation__link {
  display: block;
  text-decoration: none;
  color: var(--text-color);
  margin-bottom: var(--margin-s);
  border-bottom: 1px solid var(--border-color-beige);
  padding: var(--padding-m);
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
}
.navigation__link:first-child {
  border-top: 1px solid var(--border-color-beige);
}
.navigation__link:active {
  color: black;
}
.navigation__link--active {
  font-weight: 600;
}

@media (min-width: 481px) {
  .menu-wrapper {
    left: 55vw;
    width: calc(100% - 55vw);
  }

  .site-title-icon {
    display: block;
    height: 20px;
    width: 20px;
    color: #e2c743;
    margin-left: 8px;
    margin-top: 1px;
  }
}
@media (min-width: 1025px) {
  header {
    padding: var(--padding-l) 0;
  }

  .menu-overlay,
.close-menu-btn {
    display: none;
  }

  .site-title {
    font-size: 2.4rem;
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-wrapper {
    position: static;
    display: flex;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    transform: translateX(0);
  }

  .menu-button {
    display: none;
  }

  .navigation {
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  .navigation__link {
    margin-bottom: 0;
    padding: 0;
    font-size: 1.6rem;
    border: none;
  }
  .navigation__link:first-child {
    border-top: none;
  }
  .navigation__link:not(:last-child) {
    padding: 0 var(--padding-l) 0 0;
    margin-bottom: 0;
  }
  .navigation__link:hover {
    text-decoration: underline;
  }
}</style>
